.contact-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.contact-cal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 4rem;
    width: 40%;
    height: 100%;
}

.contact-cal h3 {
    color: #172554;
    text-align: center;
    padding-bottom: 1rem;
}

.contact-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
    background-color: #662e68;
    color: white;
    justify-content: center;
    text-align: center;
    padding: 3rem;
}

.text-image {
    padding: 3rem;
    height: 40%;
    width: 100%;
}

.text-image img{
    width: 100%;
}
.contact-footer {
    position: relative;
    width: 100%;
    height: 155px;
    bottom: 0;
    align-items: center;
    margin-top: 5px;
}

.contact-footer Footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 155px;
}

@media (max-width: 800px) {
    .contact-page {
        height: auto;
    }

    .contact-body {
        flex-direction: column;
        justify-content: center;
        background-color: #662e68;
    }

    .contact-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding: 3rem;
    }

    .text-image {
        display: none;
    }

    .contact-cal{
        display: flex;
        width: 100%;
        background: white;
    }
}