body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
    min-height: 100vh;
}
.embla {
    overflow: hidden;
}
.embla__container {
    display: flex;

}
.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}

/* Mobile layout */
@media (max-width: 814px) {
    .home {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: space-between;
        width: 100%;
    }

    .home .home-body {
        display: flex;
        flex-direction: column;
    }

    .home .home-body .welcome-message {
        text-align: left;
        color: #172554;
        padding: 5%;
    }

    .home .home-body .welcome-message h2 {
        text-align: center;
        font-weight: bold;
        text-align: center;
    }

    .home .home-body .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .home .home-body .carousel-container {
        display: none;
    }

    .home .home-body .celphone-carousel-image {
        display: flex;
        max-width: 100%;
        width: 100%;
    }

    .home .home-body .celphone-carousel-image img{
        display: flex;
        max-width: 100%;
    }


    .home-footer {
        padding-top: 40px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        position: relative;
        bottom: 0;
    }
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {
    .home {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .home-body {
        display: flex;
        flex-direction: column;
    }
    .welcome-message {
        text-align: left;
        color: #172554;
        padding: 5%;
    }

    .welcome-message h2 {
        text-align: center;
        font-weight: bold;
        text-align: center;
    }

    .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .carousel-container {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
    }

    .home-footer {
        padding-top: 50px;
    }
}

/* Desktop layout */
@media (min-width: 1024px) {
    .home {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .home .home-body {
        display: flex;
        max-width: 1080px;
        flex-direction: column;
        justify-content: center;
    }

    .home .home-body .welcome-message {
        text-align: left;
        color: #172554;
        padding: 2%;
    }

    .home .home-body .welcome-message h2 {
        font-weight: bold;
        text-align: center;
    }

    .home .home-body .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .home .home-body .carousel-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .home .home-body .celphone-carousel-image {
        display: none;
    }

    .home-footer{
        position: absolute;
        width: 100%;
        height: 155px;
        bottom: 0;
        align-items: center;
        margin-top: 5px;
    }

    .home-footer Footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 155px;
    }
}
/* Footer.css */

footer {
    background: #172554;
    color: #fff;
    padding: 2rem 0;
}

.footer-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1 1;
    margin: 1rem;
}

.address:hover{
    cursor: pointer;
}

.address,
.phone,
.email,
.book {
    text-align: center;
}

.address svg,
.phone svg,
.email svg,
.book svg {
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
}

.phone a,
.book a {
    color: #fff;
    text-decoration: none;
}

.book {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {
    .col {
        flex-basis: 100%;
    }
}
/* Mobile layout */
@media (max-width: 814px) {
    .about-page {
        padding: 20px;
    }

    .about-top {
        flex-direction: column;
    }

    .about-text,
    .about-image {
        width: 100%;
    }

    .about-image img {
        width: 100%;
    }

    .about-text h2 {
        text-align: center;
    }

    .about-image-2 {
        display: none;
    }

    .gmap_canvas iframe {
        padding-top: 20px;
        height: 200px;
        width: 100%
    }
    .not-on-cellphone-image {
        display: none;
    }

    .footer {
        padding-top: 20px;
    }
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {

    .about-page {
        padding: 40px;
    }

    .about-top {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .about-text,
    .about-image {
        width: 50%;
    }

    .about-image {
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
    }

    .about-image img {
        width: 90%;
        padding: 20px;
    }

    .gmap_canvas iframe {
        height: 250px;
        width: 100%;
    }

    .footer {
        padding-top: 40px;
    }

}

/* Desktop layout */
@media (min-width: 1024px) {

    .about-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .about-top {
        max-width: 1080px;
        flex-direction: row;
        display: flex;
        justify-content: center;
    }

    .about-text {
        width: 50%;
    }

    .about-text h2 {
        text-align: center;
        margin-top: 10px;
    }

    .about-text p {
        text-align: justify;
        padding: 10px;
    }
    .about-image {
        width: 45%;
    }

    .about-image img {
        width: 100%;
        padding: 20px 0 20px 30px;
    }

    .gmap_canvas {
        width: 100%;
        height: 250px;
    }

    .gmap_canvas iframe {
        width: 100%;
        height: 250px;
    }

    .about-footer{
        position: relative;
        width: 100%;
        bottom: 0;
        height: 155px;
        align-items: center;
        margin-top: 5px;
    }

    .footer Footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 155px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container{
    width: 95%;
    min-height: 100vh;
    position: relative;
    margin: 0 auto;
}

.container .media-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
    margin: 20px auto;
}

.container .media-container .media{
    height: 220px;
    width: 100%;
    border: 5px solid #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    cursor: pointer;
}

.container .media-container .media :is(video, img){
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s linear;
}

.container .media-container .media:hover :is(video, img){
    transform: scale(1.1);
}

.container .popup-media :is(video, img){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 95%;
    max-height: 95%;
    object-fit: contain;
    border: 3px solid #fff;
}

.container .popup-media span{
    position: absolute;
    top: 5px;
    right: -30px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    -webkit-user-select: none;
            user-select: none;
}


.body{
    background: #0f0f22;
}

.tour-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.tour-footer {
    background-color: #172554;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    height: 8%;
}
.contact-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
}

.contact-cal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 4rem;
    width: 40%;
    height: 100%;
}

.contact-cal h3 {
    color: #172554;
    text-align: center;
    padding-bottom: 1rem;
}

.contact-text {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
    background-color: #662e68;
    color: white;
    justify-content: center;
    text-align: center;
    padding: 3rem;
}

.text-image {
    padding: 3rem;
    height: 40%;
    width: 100%;
}

.text-image img{
    width: 100%;
}
.contact-footer {
    position: relative;
    width: 100%;
    height: 155px;
    bottom: 0;
    align-items: center;
    margin-top: 5px;
}

.contact-footer Footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 155px;
}

@media (max-width: 800px) {
    .contact-page {
        height: auto;
    }

    .contact-body {
        flex-direction: column;
        justify-content: center;
        background-color: #662e68;
    }

    .contact-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding: 3rem;
    }

    .text-image {
        display: none;
    }

    .contact-cal{
        display: flex;
        width: 100%;
        background: white;
    }
}
.active {
    background-color: #662e68;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #322b5b;
    color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

nav .logo a img {
    height: 80px;
}

nav .title {
    font-size: 150%;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
    margin-right: 3rem;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    background-color:#662e68 ;
}

nav .menu {
    display: none;
    position: absolute;
    top: 1.7rem;
    right: 0;
    flex-direction: column;
    width: 80px;
    height: 100%;
}

nav .menu .icon {
    width: 100%;
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {
    nav .title {
        font-size: 135%;
        margin: 1rem;
    }
}

@media (max-width: 815px) {
    nav .menu {
        display: flex;
    }
    nav .title {
        font-size: 115%;
        margin: 1rem;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}
