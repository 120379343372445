.body{
    background: #0f0f22;
}

.tour-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.tour-footer {
    background-color: #172554;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: white;
    text-align: center;
    height: 8%;
}