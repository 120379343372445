/* Mobile layout */
@media (max-width: 814px) {
    .home {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: space-between;
        width: 100%;
    }

    .home .home-body {
        display: flex;
        flex-direction: column;
    }

    .home .home-body .welcome-message {
        text-align: left;
        color: #172554;
        padding: 5%;
    }

    .home .home-body .welcome-message h2 {
        text-align: center;
        font-weight: bold;
        text-align: center;
    }

    .home .home-body .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .home .home-body .carousel-container {
        display: none;
    }

    .home .home-body .celphone-carousel-image {
        display: flex;
        max-width: 100%;
        width: 100%;
    }

    .home .home-body .celphone-carousel-image img{
        display: flex;
        max-width: 100%;
    }


    .home-footer {
        padding-top: 40px;
        height: fit-content;
        position: relative;
        bottom: 0;
    }
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {
    .home {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .home-body {
        display: flex;
        flex-direction: column;
    }
    .welcome-message {
        text-align: left;
        color: #172554;
        padding: 5%;
    }

    .welcome-message h2 {
        text-align: center;
        font-weight: bold;
        text-align: center;
    }

    .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .carousel-container {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
    }

    .home-footer {
        padding-top: 50px;
    }
}

/* Desktop layout */
@media (min-width: 1024px) {
    .home {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .home .home-body {
        display: flex;
        max-width: 1080px;
        flex-direction: column;
        justify-content: center;
    }

    .home .home-body .welcome-message {
        text-align: left;
        color: #172554;
        padding: 2%;
    }

    .home .home-body .welcome-message h2 {
        font-weight: bold;
        text-align: center;
    }

    .home .home-body .welcome-message p {
        font-size: 18px;
        line-height: 1.5;
    }

    .home .home-body .carousel-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .home .home-body .celphone-carousel-image {
        display: none;
    }

    .home-footer{
        position: absolute;
        width: 100%;
        height: 155px;
        bottom: 0;
        align-items: center;
        margin-top: 5px;
    }

    .home-footer Footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 155px;
    }
}