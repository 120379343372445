/* Mobile layout */
@media (max-width: 814px) {
    .about-page {
        padding: 20px;
    }

    .about-top {
        flex-direction: column;
    }

    .about-text,
    .about-image {
        width: 100%;
    }

    .about-image img {
        width: 100%;
    }

    .about-text h2 {
        text-align: center;
    }

    .about-image-2 {
        display: none;
    }

    .gmap_canvas iframe {
        padding-top: 20px;
        height: 200px;
        width: 100%
    }
    .not-on-cellphone-image {
        display: none;
    }

    .footer {
        padding-top: 20px;
    }
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {

    .about-page {
        padding: 40px;
    }

    .about-top {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .about-text,
    .about-image {
        width: 50%;
    }

    .about-image {
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
    }

    .about-image img {
        width: 90%;
        padding: 20px;
    }

    .gmap_canvas iframe {
        height: 250px;
        width: 100%;
    }

    .footer {
        padding-top: 40px;
    }

}

/* Desktop layout */
@media (min-width: 1024px) {

    .about-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }

    .about-top {
        max-width: 1080px;
        flex-direction: row;
        display: flex;
        justify-content: center;
    }

    .about-text {
        width: 50%;
    }

    .about-text h2 {
        text-align: center;
        margin-top: 10px;
    }

    .about-text p {
        text-align: justify;
        padding: 10px;
    }
    .about-image {
        width: 45%;
    }

    .about-image img {
        width: 100%;
        padding: 20px 0 20px 30px;
    }

    .gmap_canvas {
        width: 100%;
        height: 250px;
    }

    .gmap_canvas iframe {
        width: 100%;
        height: 250px;
    }

    .about-footer{
        position: relative;
        width: 100%;
        bottom: 0;
        height: 155px;
        align-items: center;
        margin-top: 5px;
    }

    .footer Footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 155px;
    }
}