.active {
    background-color: #662e68;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #322b5b;
    color: white;
    position: sticky;
    top: 0;
}

nav .logo a img {
    height: 80px;
}

nav .title {
    font-size: 150%;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
    margin-right: 3rem;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    background-color:#662e68 ;
}

nav .menu {
    display: none;
    position: absolute;
    top: 1.7rem;
    right: 0;
    flex-direction: column;
    width: 80px;
    height: 100%;
}

nav .menu .icon {
    width: 100%;
}

/* Tablet layout */
@media (min-width: 815px) and (max-width: 1023px) {
    nav .title {
        font-size: 135%;
        margin: 1rem;
    }
}

@media (max-width: 815px) {
    nav .menu {
        display: flex;
    }
    nav .title {
        font-size: 115%;
        margin: 1rem;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}