/* Footer.css */

footer {
    background: #172554;
    color: #fff;
    padding: 2rem 0;
}

.footer-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1;
    margin: 1rem;
}

.address:hover{
    cursor: pointer;
}

.address,
.phone,
.email,
.book {
    text-align: center;
}

.address svg,
.phone svg,
.email svg,
.book svg {
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
}

.phone a,
.book a {
    color: #fff;
    text-decoration: none;
}

.book {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {
    .col {
        flex-basis: 100%;
    }
}